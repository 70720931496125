<template>
  <div class="box">
    <div class="tabNew" v-if="tabBar && !isApp" :class="navBarFixed ? 'navBarFixed' : ''">
      <div class="tabTop">
        <div>
          <ul>
            <img class="hongshi" src="../../../../static/common/wap/hongshi.jpg" alt="">
            <p>
              <span style="font-weight:600;font-size:13px;">红狮启富</span>
              <span>应用版本：5.0.0</span>
              <span>更新时间：2024年09月02日</span>
            </p>
          </ul>
          <img @click="downLoadApp" class="downLoadApp" src="../../../../static/common/wap/downloadApp.png" alt="">
        </div>
      </div>
      <p class="tabBottom">
        <span>应用权限:查看权限 隐私权限: 查看权限</span>
        <span>APP开发者: 香港红狮集团有限公司 应用名称:红狮启富</span>
      </p>
      <img @click="() => { tabBar = false; }" class="closer" src="../../../../static/common/wap/closer.png" alt="">
    </div>
    <div class="BannerBox">
      <img class="banner" src="/subject/sc_2024/wap/banner.png" alt="">
    </div>
    <div class="content">
      <div class="logins">
        <div id="login">
          <div class="interactive">
            <div class="line">
              <img src="/subject/sc_2024/wap/line.png" alt="">
            </div>
            <input v-model="form.phone" ref="inputElement" type="text" placeholder="请输入手机号">
            <div class="codeBox">
              <input v-model="form.code" type="text" placeholder="请输入验证码">
              <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
              <span v-else>重新获取({{ verSecond }})</span>
            </div>
            <img class="loginBtn" @click="submit(form)" src="/subject/sc_2024/wap/ljlq.png" alt="">
            <div class="djs">
              <div class="djs-box">
                倒计时
                <span class="logdjs-span">{{ days }}</span>天
                <span class="logdjs-span">{{ hours }}</span>时
                <span class="logdjs-span">{{ minutes }}</span>分
                <span class="logdjs-span">{{ seconds }}</span>秒
              </div>
            </div>
          </div>
          <div v-if="controlImgCodeDialog" class="codeDialog">
            <img :src="imgcodelocal" alt="">
            <p>
              <span>图形验证码:</span>
              <input type="text" v-model="form.codeNumber">
            </p>
            <div>
              <button @click="() => { this.controlImgCodeDialog = false }">取消</button>
              <button @click="confirm(form.codeNumber)">确定</button>
            </div>
          </div>
        </div>
      </div>
      <div class="part">
        <div class="part-con">
          <div class="line"></div>
          <div class="img1"></div>
          <span class="part-1-span" @click="showDialog = true">活动详情</span>
          <div class="list">
            <div class="gift1" @click="focusInput()"></div>
            <div class="gift2">
              <div class="g100" @click="focusInput()"></div>
              <div class="g500" @click="focusInput()"></div>
            </div>
            <div class="gift-t"></div>
            <div class="part-1-bth">
              <div class="zxkf" @click="live800"></div>
              <div class="lzj" @click="focusInput()"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="part2">
        <div class="part-2-bth">
          <div class="line152"></div>
          <div class="p2con">
            <img class="hq1" src="/subject/sc_2024/wap/hq1.png" alt="">
            <img class="hq2" src="/subject/sc_2024/wap/hq2.png" alt="">
            <img class="phone" src="/subject/sc_2024/wap/phone.png" alt="">
          </div>
          <div class="anniu">
            <div class="zxkf" @click="live800"></div>
            <div class="lzj" @click="focusInput()"></div>
          </div>
        </div>
      </div>
      <div class="part3">
        <div class="part-3-bth">
          <div class="line205"></div>
          <div class="list">
            <div class="con1"></div>
            <div class="con2"></div>
            <div class="con3"></div>
            <div class="con4"></div>
            <div class="con5"></div>
          </div>
          <div class="anniu">
            <div class="zxkf" @click="live800"></div>
            <div class="lzj" @click="focusInput()"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDialog" class="dialog">
      <img class="closer" @click="showDialog = false" src="/subject/mayPage/wap/closer5.png" alt="">
      <ul class="dialogContent">
        <h3>活动规则</h3>
        <li>
          <span>活动对象：</span>
          活动期间，在本平台首次开立真实账户，及首次入金的客户
        </li>
        <li>
          <span>活动时间：</span>
          即日起至{{ formattedDateString }}
        </li>
        <li style="color:#ad0000;">
          <span>活动规则：</span><br>
          活动期间，新用户可享双重福利：<br />
          1. 开户领$588美元赠金，交易后可提现；<br />
          2. 首次入金，最高领$20000+￥1588新人礼包！
        </li>
        <li>
          <span>开户领$588美元赠金：</span><br>
          完成首次开户 领$588美元赠金，有效期90天内每交易一手伦敦金/伦敦银，获得相对应的赠金金额返还（未满一手按照实际交易手数占一手的比例计算）<br>
          <img src="/subject/mayPage/wap/table1000.png" alt="" />
        </li>
        <li>
          <span>首入金领$20000+￥1588</span><br>
          首次充值，送美元赠金+美元现金+人民币红包；<br>
          24小时内充多少送多少，最高领$20000+￥1588 迎新大礼包；<br>
          首入金奖励如下：<br>
        </li>
     
        <li class="imgLiBox">
          <img src="/subject/mayPage/WAP$220.png" alt="">
        </li>
        <li class="imgLiBox">
          <img src="/subject/mayPage/WAP$600.png" alt="">
        </li>
        <li class="imgLiBox">
          <img src="/subject/mayPage/WAP$2100.png" alt="">
        </li>
        <li class="imgLiBox">
          <img src="/subject/mayPage/WAP$20100.png" alt="">
        </li>
        <li>
          <span>美元赠金：</span><br>
          <!-- <span>美元赠金：</span><br> -->
          1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br>
          2、 首入金有效期为第一笔入金后的24h内，充多少送多少；<br>
          3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br>
          4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br>
          <span style="color:#000000">
            更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。
          </span>
        </li>
        <li>
          <span>美元现金：</span><br>
          1、美元现金直接入账，可交易，可提现；<br>
          2、用户在30天内完成指定交易手数即可提现，品种限伦敦金/伦敦银；<br>
          3、30天内未达到交易手数要求，或者活动期间取款时未完成对应手数交易，系统将收回美元现金金额；<br>
          举例说明：举例说明：A女士，入金500美元，到账600美元，入金后30天内完成了20手交易，将100美元现金提现
        </li>
        <li>
          <span>注意事项：</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog" @click="() => { showDialog = false }"></div>
    <div class="bottomBar" v-if="tabBar && !isApp" :class="BarIsBottom ? 'BarIsBottom' : ''">
      <p>
        <img src="../../../../static/common/wap/call.png" alt="">
        <span @click="live800">咨询客服</span>
      </p>
      <p>
        <img src="../../../../static/common/wap/dollars.png" alt="">
        <span @click="goLogin">立领$20000赠金+￥1588赠金红包</span>
      </p>
    </div>
  </div>
</template>
<script>
import ContactusVue from "../../Contactus.vue";
import { getGuid } from '../../../../utils/guid';
import { verifyImgCode, quickRegister } from '../../../../api/info';
import { getJfConfigurationValue } from "../../../../api/subject/hdtime";
import { mapState  } from "vuex";

export default {
  name: 'mayPageWap',
  components: {
    ContactusVue,
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      navBarFixed: false,
      scroll: '',
      screenWidth: null,
      form: {
        phone: '',
        code: '',
        codeNumber: '',
      },
      // 控制显示验证码或读秒
      verControl: true,
      // 定时器倒计时
      refreshData: null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog: false,
      // 图片验证码地址
      imgcodelocal: '',
      slider: true,
      rulesModel: false,
      isApp: false,
      setStep: 1,
      showDialog: false,
      tabBar: true,
      BarIsBottom: false,
      formattedDateString:'',
      timer: null,
    }
  },
  computed: {
    ...mapState('common', {
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  created() {
    // this.updateCountdown();
    // setInterval(this.updateCountdown, 1000);
    this.$emit("controlShow", true);
    if (this.$route.query.isApp) {
      this.isApp = true;
    }
    window.addEventListener('scroll', this.scrollBottom);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    window.addEventListener('DOMContentLoaded', this.setRemUnit(), false);
    this.getConfigurationValue()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom) //页面离开后销毁监听事件
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.timer);
  },
  watch: {
    screenWidth: function (n) {
      if (n > 750) {
        this.$router.push({
          name: "sc_2024",
        });
      }
    },
  },
  methods: {
    async getConfigurationValue() {
        const res = await getJfConfigurationValue(JSON.stringify({"key":"SCjiezhishijian"}))
        const { Data } = res;
        this.updateCountdown(Data.replace(/-/g, '/'));
        this.timer = setInterval(() => this.updateCountdown(Data.replace(/-/g, '/')), 1000);
        this.formattedDateString = this.formatDates(Data);  
    },
    formatDates(dateString) {  
        // 解析日期时间字符串  
      const date = new Date(dateString);  
    
      // 检查日期是否有效  
      if (isNaN(date.getTime())) {  
          throw new Error('Invalid date string');  
      }  
    
      // 使用模板字符串格式化日期和时间  
      const formattedDate = `${date.getFullYear()}年${padZero(date.getMonth() + 1)}月${padZero(date.getDate())}日${padZero(date.getHours())}:${padZero(date.getMinutes())}`;  
    
      return formattedDate;  
    
      // 辅助函数：在数字前面添加前导零（如果需要）  
      function padZero(num) {  
          return num.toString().padStart(2, '0');  
      }  
    },
    // 设置根字体大小自适应使用
    setRemUnit() {
      var docEl = document.documentElement;
      var baseSize = 750; // 设计稿宽度
      var targetBaseFontSize = 10; // 1rem对应的目标像素值
      function resizeHandler() {
        var clientWidth = docEl.clientWidth || window.innerWidth;
        docEl.style.fontSize = (clientWidth / baseSize) * targetBaseFontSize + 'px';
      }
      // 初始设置
      resizeHandler();
      // 添加窗口resize事件监听器
      window.addEventListener('resize', resizeHandler, false);
    },
    // 点击获取焦点
    focusInput() {
      this.$refs.inputElement.focus();
    },
    // 倒计时
    updateCountdown(needTime) {
      const today = new Date();
      const endOfMonth = new Date(needTime).getTime();
      // const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let timeLeft = endOfMonth - today;
      if(timeLeft <= 0) {
        timeLeft = 0
        clearInterval(this.timer)
      }
      this.days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(2, '0');
      this.hours = String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      this.minutes = String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      this.seconds = String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0');

    },
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 提交验证码
    confirm(attr) {
      if (attr) {
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res => {
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      } else {
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form) {
      if (form.phone && form.code) {
        let data = {
          IsQuickRregister: true,
          Mobile: form.phone,
          Code: form.code,
          Token: this.guid,
          LoginType: 1,
          AuthMode: 1,
        };
        quickRegister(data).then(res => {
          console.log(res);
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            window.location.href = 'https://www.rlcproltd.net/uc/login';
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      } else {
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
      }
    },
    // 点击获取验证码
    verCode(attr) {
      if (this.isPhone(attr)) {
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      } else {
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 跳转客服
    live800() {
      window.open(
        this.$parent.live800UrlUc
      );
    },
    // 点击下载app
    downLoadApp() {
      var u = navigator.userAgent;
      if (/(iPhone|iPad|iPod|iOS)/i.test(u)) { //苹果
        try {
          window.webkit.messageHandlers.gotoRegisterReal.postMessage('1');
        } catch (error) {
          window.open(this.iosMt5DownloadUrl);
        }
      } else { // 安卓
        try {
          window.android.gotoRegisterReal();
        } catch (error) {
          window.open(this.androidDownloadUrl);
        }
      }
    },
    // 登录
    goLogin() {
      window.open('https://www.rlcproltd.net/uc/login');
    },
    // 判断是否滑到底部
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // this.BarIsBottom = true;
      }
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    //获取当月最后一天的日期
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? '0' + month : month; //月份补 0
      return year + '年' + month + '月' + lastDate + '日 23:59:59';
    }
  }
}
</script>
<style lang="less" scoped>
html {
  font-size: 16px;
}

.box {
  width: 100%;
  height: auto;
  background: url('/subject/newYear9911/wap/bg.png') no-repeat center;
  background-size: 100%;

  .tabNew {
    width: 100%;
    height: 89px;
    background: url('../../../../static/common/wap/tab.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 3px;

    // position: relative;
    .tabTop {
      width: 100%;
      height: 51px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          .hongshi {
            width: 37.5px;
            margin: 0 10px;
          }

          p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 12px;
          }
        }

        .downLoadApp {
          width: 101.5px;
          margin-top: 10px;
          margin-right: 10px;
        }
      }
    }

    .tabBottom {
      font-size: 12px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 10px;
      margin: 5px 0;
    }

    .closer {
      width: 12px;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .BannerBox {
    // position: relative;
    width: 100%;
    height: auto;

    .banner {
      width: 100%;
      display: block;
    }
  }

  .content {
    width: 100%;
    height: 556rem;
    box-sizing: border-box;
    padding: 0;
    margin-top: -46rem;

    .logins {
      width: 100%;
      height: auto;
      position: relative;
      top: -5.5rem;

      #login {
        width: 68.9rem;
        height: 70rem;
        margin: 0 auto;
        background: rgba(199, 8, 18, 0.76);
        opacity: .88;
        border: 1px solid #fff3cc;
        border-radius: 15px;

        .interactive {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          .line {
            width: 100%;
            height: 13rem;
            margin: 0 auto;
            text-align: center;
            padding-top: 3rem;

            img {
              display: block;
              margin: 0 auto;
              width: 39.3rem;
            }
          }

          input:focus {
            outline: none;
          }

          input {
            width: 62.9rem;
            height: 8.8rem;
            background: #fff;
            border: none;
            border-radius: 1rem;
            font-size: 2.6rem;
            text-indent: 2.5rem;
            list-style: none;
            border: 1px solid #e31224;
          }

          .codeBox {
            height: 8.8rem;
            position: relative;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            margin: 3.5rem 0;

            span {
              width: 17.8rem;
              height: 6.4rem;
              line-height: 6.4rem;
              background-color: #fcdeae;
              font-size: 3.1rem;
              border-radius: 1rem;
              text-align: center;
              color: #cf0405;
              position: absolute;
              top: 1.5rem;
              right: 1.2rem;
            }
          }

          .loginBtn {
            width: 57rem;
            height: 12rem;
            margin-top: 2rem;
          }

          .djs {
            width: 100%;
            text-align: center;
            color: #fff2d5;

            span {
              font-weight: 600;
            }

            .djs-box {
              margin-top: 1rem;
              font-size: 3.6rem;

              span {
                display: inline-block;
                width: 6.6rem;
                height: 6.8rem;
                line-height: 6.8rem;
                background-color: #a10b11;
                color: #fff2d5;
                margin: 0 0.5rem;
                border-radius: 1.3rem;
                border: 1px solid #fcdaa6;
              }
            }
          }
        }

        .codeDialog {
          width: 90%;
          height: 180px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fee6e4;
          border-radius: 15px;
          z-index: 99;
          border: 1px solid #ffdbdb;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          img {
            width: 120px;
            margin-bottom: 0;
          }

          p {
            span {
              color: #5f0e15;
              font-size: 15px;
              font-weight: 600;
            }

            input {
              width: 150px;
              height: 30px;
              background-color: #ffffff;
              border: none;
              margin-left: 10px;
              text-indent: 10px;
              color: #5f0e15;
              font-size: 14px;
            }
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              width: 90px;
              height: 30px;
              border: 1px solid #f64b44;
              background-color: #ffffff;
              color: #f64b44;
              font-size: 15px;
              border-radius: 8px;
              margin: 0 10px;
            }

            button:nth-child(2) {
              background-color: #f64b44;
              color: #ffffff;
            }
          }
        }
      }
    }

    .part {
      width: 100%;
      height: auto;

      .part-con {
        width: 68.1rem;
        height: 121rem;
        margin: 0 auto;
        background: url('/subject/newYear9911/wap/1.png') no-repeat center;
        background-size: 100%;

        .line {
          width: 100%;
          height: 13.8rem;
        }

        .img1 {
          width: 33.9rem;
          height: 4.5rem;
          margin: 0 auto;
          background: url('/subject/newYear9911/wap/newuser.png') no-repeat center;
          background-size: 100%;
          margin-bottom: 2rem;
        }

        .part-1-span {
          width: 10rem;
          height: 3.8rem;
          margin: 0 auto;
          text-decoration: underline;
          color: #c0705e;
          font-size: 1.8rem;
          display: block;
          margin-bottom: 2rem;
        }

        .list {
          width: 100%;
          height: auto;

          .gift1 {
            width: 57rem;
            height: 34.5rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/1000.png') no-repeat center;
            background-size: 100%;
          }

          .gift2 {
            width: 100%;
            height: 32.4rem;
            display: flex;
            justify-content: space-between;
            margin-top: 1.5rem;

            .g100 {
              width: 27.1rem;
              height: 32.4rem;
              background: url('/subject/sc_2024/wap/100.png') no-repeat center;
              background-size: 100%;
              margin: 0 3.8rem;
            }

            .g500 {
              width: 27.1rem;
              height: 32.4rem;
              background: url('/subject/sc_2024/wap/500.png') no-repeat center;
              background-size: 100%;
              margin: 0 3.8rem;
            }
          }

          .gift-t {
            width: 60rem;
            height: 13.6rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/gift-t.png') no-repeat center;
            background-size: 100%;
            margin-bottom: 2rem;
          }

          .part-1-bth {
            width: 100%;
            height: 13.3rem;
            display: flex;
            justify-content: center;

            .zxkf {
              width: 31rem;
              height: 13.3rem;
              background: url('/subject/sc_2024/wap/serve.png') no-repeat center;
              background-size: 100%;
            }

            .lzj {
              width: 31rem;
              height: 13.3rem;
              background: url('/subject/sc_2024/wap/openacc.png') no-repeat center;
              background-size: 100%;
            }
          }
        }

      }
    }

    .part2 {
      width: 100%;
      height: auto;
      margin-top: 7.8rem;

      .part-2-bth {
        width: 68.1rem;
        height: 192.4rem;
        margin: 0 auto;
        background: url('/subject/newYear9911/wap/2.png') no-repeat center;
        background-size: 100%;

        .line152 {
          width: 100%;
          height: 15.2rem;
        }

        .p2con {
          img {
            display: block;
            margin: 0 auto;
          }

          .hq1 {
            width: 63.5rem;
          }

          .hq2 {
            width: 53.8rem;
            margin-top: 3rem;
            left: 6rem;
            position: relative;
          }

          .phone {
            width: 32.8rem;
            margin-top: -4rem;
          }

        }

        .anniu {
          width: 100%;
          height: 13.3rem;
          display: flex;
          justify-content: center;

          .zxkf {
            width: 31rem;
            height: 13.3rem;
            background: url('/subject/sc_2024/wap/serve.png') no-repeat center;
            background-size: 100%;
          }

          .lzj {
            width: 31rem;
            height: 13.3rem;
            background: url('/subject/sc_2024/wap/openacc.png') no-repeat center;
            background-size: 100%;
          }
        }
      }
    }

    .part3 {
      width: 100%;
      height: auto;
      margin-top: 7.8rem;

      .part-3-bth {
        width: 68.1rem;
        height: 138.1rem;
        margin: 0 auto;
        background: url('/subject/newYear9911/wap/3.png') no-repeat center;
        background-size: 100%;

        .line205 {
          width: 100%;
          height: 20.5rem;
        }

        .list {
          width: 100%;
          height: auto;
          margin-bottom: 4.8rem;

          .con1 {
            width: 65.7rem;
            height: 19.9rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/p3-1.png') no-repeat center;
            background-size: 98%;
            margin-bottom: 1.7rem;
          }

          .con2 {
            width: 65.7rem;
            height: 19.9rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/p3-2.png') no-repeat center;
            background-size: 98%;
            margin-bottom: 1.7rem;
          }

          .con3 {
            width: 65.7rem;
            height: 19.9rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/p3-3.png') no-repeat center;
            background-size: 98%;
            margin-bottom: 1.7rem;
          }

          .con4 {
            width: 65.7rem;
            height: 19.9rem;
            margin: 0 auto;
            background: url('/subject/sc_2024/wap/p3-4.png') no-repeat center;
            background-size: 98%;
            margin-bottom: 4.7rem;
          }

          .con5 {
            width: 48.4rem;
            height: 6.4rem;
            margin: 0 auto;
            background: url('/subject/newYear9911/wap/p3-5.png') no-repeat center;
            background-size: 100%;
            margin-bottom: 1.7rem;
          }
        }

        .anniu {
          width: 100%;
          height: 13.3rem;
          display: flex;
          justify-content: center;

          .zxkf {
            width: 31rem;
            height: 13.3rem;
            background: url('/subject/sc_2024/wap/serve.png') no-repeat center;
            background-size: 100%;
          }

          .lzj {
            width: 31rem;
            height: 13.3rem;
            background: url('/subject/sc_2024/wap/openacc.png') no-repeat center;
            background-size: 100%;
          }
        }
      }
    }
  }

  .dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 400px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
      width: 20px;
      height: auto;
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .dialogContent {
      box-sizing: border-box;
      padding: 25px 15px 15px;
      overflow: hidden;
      background: url('/subject/mayPage/rulesBackground.png') no-repeat;
      background-size: 100% 100%;

      h3 {
        color: #d88f4f;
        text-align: center;
        margin-top: 0px;
      }

      .imgLiBox {
        overflow-x: auto;

        img {
          width: 326px;
        }
      }

      li {
        font-size: 14px;
        color: #464646;
        line-height: 24px;

        span {
          font-weight: 600;
          color: #d88f4f;
        }

        img {
          width: 100%;
          height: auto;
          margin: 20px 0;
        }

        table {
          width: 100%;
          border: 1px solid #464646;
          border-radius: 10px;

          tr {
            th {
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #464646;
              border-right: 1px solid #464646;
              padding: 5px;
            }

            th:last-child {
              border-right: 1px solid transparent;
            }
          }

          tr:nth-child(1) {
            th {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }

  .bottomBar {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    background-color: #fff0ed;
    position: fixed;
    bottom: 0;

    p {
      width: 85%;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      img {
        width: 19px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
      }
    }

    p:nth-child(1) {
      border: 1px solid #d52226;
      border-radius: 20px;
      margin-bottom: 10px;

      span {
        color: #d52226;
      }
    }

    p:nth-child(2) {
      background: url('../../../../static/common/wap/botBtn.png') no-repeat;
      background-size: 100% 100%;

      span {
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 384px) {
  .box {
    .BannerBox {
      #login {
        bottom: 0;
      }
    }
  }

  .box .content {
    .part1 {
      .btu {
        top: -4rem;
      }
    }

    .part2 {
      .btu {
        top: -4rem;
      }
    }

    .part3 {}

    .part4 {
      .btu4 {
        .totop {
          top: -29.625rem;
        }
      }

      .down {
        margin-top: -13.5rem;

        .android,
        .ios {
          text-align: center;
          font-size: 14px;

          span {
            display: inline-block;
            position: relative;
            top: -6px;
          }
        }
      }
    }

    .part5 {
      margin-top: -3.5rem;
    }
  }

  .box {
    .openTabBar {
      height: 3340px !important;
    }
  }
}

@media screen and (min-width: 385px) and (max-width: 409px) {
  .box .content {
    .part4 {
      .btu4 {
        .totop {
          top: -32.0625rem;
        }
      }

      .down {
        margin-top: -14.5rem;

        .android,
        .ios {
          text-align: center;
          font-size: 14px;

          span {
            display: inline-block;
            position: relative;
            top: -6px;
          }
        }
      }
    }
  }

  .box {
    .openTabBar {
      height: 3230px !important;
    }

    .BannerBox {
      #login {
        bottom: 1em;
      }
    }
  }
}

@media screen and (min-width : 410px) and (max-width: 450px) {
  .box {
    .BannerBox {
      #login {
        bottom: 2rem;
      }
    }
  }

  .box .content {
    .part4 {
      .btu4 {
        .totop {
          top: -34.0625rem;
        }
      }

      .down {
        margin-top: -15.2rem;

        .android,
        .ios {
          text-align: center;
          font-size: 14px;

          span {
            display: inline-block;
            position: relative;
            top: -6px;
          }
        }
      }
    }
  }

  .box {
    .openTabBar {
      // height: 3945px !important;
    }
  }
}
</style>